.qrmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.searchButton {
  margin-left: 13px;
}

.credmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 800px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* padding: 20px; */
}

@media only screen and (max-width: 600px) {
  .credmodal.editModal {
    width: calc(100vw - 30px);
    /* top: calc(100vw + 400px); */
  }
  .credmodal.arrivedModal{
    width: calc(100vw - 30px);
  }
  .credmodal.issueModal{
    width: calc(100vw - 30px);
  }
  .searchButton {
    margin-left: 0;
    margin-top: 10px;
  }

}
@media only screen and (min-width: 768px) and (max-width: 959px){
  .credmodal.editModal {
    width: calc(100vw - 80px);
    /* top: calc(100vw - 120px); */
  }
  .credmodal.arrivedModal{
    width: calc(100vw - 90px);
  }
  .credmodal.issueModal{
    width: calc(100vw - 80px);
  }
}

.credoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.qroverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

#spinner {
  text-align: center;
}

.qr-image {
  width: 300px;
  height: 300px;
}

.centered-content {
  align-content: center;
  text-align: center;
}

.dropdown {
  min-width: 100%;
  /* background-color: white; */
}

.menu-dropdown {
  min-width: 100%;
  background-color: white;
}
.width-70 {
  width: 50% !important;
}
.truncate-loc-name {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-button {
  max-width: 330px !important;
  flex-wrap: nowrap;
}
.rotate-table {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
table {
  white-space: nowrap;
}
.custom-dropdown{
  transform: translate3d(0,0,0) !important;
}


#arrived-modal::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 12px;
	background-color: #F5F5F5;

}

#arrived-modal::-webkit-scrollbar
{
  height: 10px;
	width: 10px;
	background-color: #F5F5F5;
}

#arrived-modal::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(143, 143, 143);
}
.custom-dropdown .dropdown-item{
  white-space: break-spaces;
}
.dropdown.dropdown-toggle.btn.btn-white{
  white-space: break-spaces; 
}
